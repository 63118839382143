import React, { useState } from "react";
import "./card.css";
import useContract from "../../hooks/useContracts";
import Logo from "../../assets/logo.jpg";

import ClipLoader from "react-spinners/ClipLoader";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function Card({ type, data, total, apy, lock }) {
  const [number, setNumber] = useState(0);
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const { stake } = useContract();
  const handleStake = async () => {
    setLoading(true);
    try {
      await stake(number, type);
      setLoading(false);
      window.location.reload();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div className="w-[22rem] bg-white text-black rounded-lg border-2 relative">
      <div className="ribbon ribbon-top-left">
        <span>LIVE</span>
      </div>

      <div className="p-6 font-medium">
        <div className="w-32 border-[] p-2 border-2 rounded-full overflow-hidden mb-5">
          <img
            className="w-full"
            src="/logo.png"
            alt="logo"
          />
        </div>
        <div className="flex justify-between mb-4">
          <span>APY</span>
          <span>{apy}%</span>
        </div>
        <div className="flex justify-between">
          <span>Lock Duration</span>
          <span>{lock}</span>
        </div>

        <div className="flex items-center flex-col mt-10 mb-8 space-y-1">
          <span>Your Wallet</span>
          <span>{total ? Number(total).toFixed(2) : 0} $Thoon</span>
        </div>

        <div className="flex flex-col space-y-5">
          <input
            className="bg-[#242323] shadow-lg shadow-[#383838] rounded-md py-2 px-4 text-gray-400 text-lg font-normal placeholder-gray-500"
            placeholder="Enter Thoon to Stake"
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />

          <button
            className="p-3 bg-[#4c1f14] text-white rounded-md flex-1"
            onClick={loading ? () => {} : handleStake}
          >
            {loading ? (
              <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              "Stake"
            )}
          </button>
        </div>
      </div>
      <hr />
      {/* <p className="text-xs font-medium text-center pb-4">
        Stake $Thoon Token and Earn 730% APY
      </p> */}
    </div>
  );
}
