import React, { useEffect, useState } from "react";
import Card from "../Card/Card";
import useContract from "../../hooks/useContracts";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
function GameZone() {
  const [data, setData] = useState(null);
  const { getStakingData } = useContract();
  const { address } = useWeb3ModalAccount();

  useEffect(() => {
    const _getData = async () => {
      const _data = await getStakingData();
      console.log(_data);
      setData(_data);
    };
    if (address) {
      _getData();
    }
  }, [address]);

  return (
    <div className="my-40 flex justify-between flex-wrap card_container">
      <div className="mb-16">
        <Card
          type="1"
          data={data?.type1}
          total={data?.totalStake1}
          apy="2"
          lock="30 Days"
        />
      </div>
      <div className="mb-16">
        <Card
          type="2"
          data={data?.type2}
          total={data?.totalStake2}
          apy="5"
          lock="3 Months"
        />
      </div>
      <div className="mb-16">
        <Card
          type="3"
          data={data?.type3}
          total={data?.totalStake3}
          apy="15"
          lock="1 Year"
        />
      </div>
      <div className="mb-16">
        <Card
          type="4"
          data={data?.type4}
          total={data?.totalStake4}
          apy="23"
          lock="2 Years"
        />
      </div>
    </div>
  );
}

export default GameZone;
