import React, { useEffect, useState } from "react";
import useContract from "../../hooks/useContracts";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import ClipLoader from "react-spinners/ClipLoader";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function Footer() {
  const [data, setData] = useState(null);
  const [id, setId] = useState(0);
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isClaiming, setIsClaiming] = useState(false);
  const [isUnstaking, setIsUnstaking] = useState(false);

  const { getStakingData, withdrawReward, unStake } = useContract();
  const { address } = useWeb3ModalAccount();

  useEffect(() => {
    const _getData = async () => {
      const _data = await getStakingData();
      console.log(_data);

      setData(_data);
    };
    if (address) {
      // call _getdata every second
      const interval = setInterval(() => {
        _getData();
      }, 1000);

      // _getData();
      return () => clearInterval(interval);
    }
  }, [address]);

  useEffect(() => {
    setId(data?.unstake[0]?.id);
  }, []);

  console.log("Data", id);

  const handleWithdraw = async () => {
    setIsClaiming(true);
    setLoading(true);
    try {
      await withdrawReward();
      setLoading(false);
      setIsClaiming(false);
      window.location.reload();
    } catch (e) {
      console.log(e);
      setLoading(false);
      setIsClaiming(false);
    }
  };

  const handleUnstake = async () => {
    setIsUnstaking(true);
    setLoading(true);
    try {
      await unStake(id);
      setLoading(false);
      setIsUnstaking(false);
      window.location.reload();
    } catch (e) {
      console.log(e);
      setIsUnstaking(false);
      console.log("Error : " + e.reason);
      window.alert("Thoon : " + e.reason);
      setLoading(false);
    }
  };

  // Function to handle selection change
  const handleSelectChange = (e) => {
    console.log(e.target.value);
    setId(e.target.value);
    setSelectedOptions({ id: e.target.value, amount: e.target.value });
  };

  return (
    <div className="flex flex-col xl:flex-row w-full xl:space-x-8">
      <div className="border-2 py-6 px-4 bg-white text-black flex justify-between items-center lg:items-end pb-14 rounded-lg w-full mb-8">
        <div>
          <div className="flex flex-col">
            <h3 className="text-3xl font-medium mb-1">
              {data ? Number(data.allStaked).toFixed(2) : 0} Thoon
            </h3>
            <span className="font-semibold">Your total Staked Token</span>
          </div>
        </div>
      </div>

      <div className="border-2 gap-5 py-6 px-4 bg-white text-black flex flex-col justify-between items-center lg:items-end pb-14 rounded-lg w-full mb-8">
        {/* stake ids */}

        <div className="flex flex-row justify-around gap-0 w-full flex-wrap">
          <p className="text-xl w-[100px]">Stake Ids</p>
          {/* multiple selection input  */}

          {/* <h3 className="text-xl">
            {data?.unstake?.length
              ? data.unstake.map((item) => {
                  return Number(item.amount) + " ";
                })
              : 0}
          </h3> */}
          <select
            className="w-30 rounded-md border-2 p-3"
            onChange={handleSelectChange}
          >
            {/* <option value="volvo">Volvo</option>
            <option value="saab">Saab</option>
            <option value="mercedes">Mercedes</option>
            <option value="audi">Audi</option> */}

            {data?.unstake?.length > 0 ? (
              data.unstake.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.amount}
                  </option>
                );
              })
            ) : (
              <option value="0"></option>
            )}
          </select>
        </div>
        <div className="flex gap-5 w-full flex-wrap">
          <input
            className="bg-[#242323] shadow-lg shadow-[#383838] rounded-md py-2 px-4 text-gray-400 text-lg font-normal placeholder-gray-500"
            placeholder="Enter Stake id to Unstake"
            type="number"
            value={id}
          />
          <button
            className="p-3 bg-[#4c1f14] text-white rounded-md flex-1 w-[100px]"
            onClick={loading ? () => {} : handleUnstake}
          >
            {isUnstaking && loading ? (
              <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              "Unstake"
            )}
          </button>
        </div>
      </div>

      <div className="border-2 py-6 px-4 bg-white text-black flex justify-between items-center lg:items-end pb-14 rounded-lg w-full mb-8 flex-col lg:flex-row">
        <div className="flex flex-col mb-4 lg:mb-0">
          <h3 className="text-3xl font-medium mb-1">
            {data ? Number(data.reward).toFixed(10) : 0}
          </h3>
          <span className="font-semibold">Total Reward</span>
        </div>
        <button
          className="p-3 bg-[#4c1f14] text-white rounded-md w-40"
          onClick={handleWithdraw}
        >
          {isClaiming && loading ? (
            <ClipLoader
              color={color}
              loading={loading}
              css={override}
              size={20}
            />
          ) : (
            "Withdraw Reward"
          )}
        </button>
      </div>
    </div>
  );
}
