import { BrowserProvider, Contract, formatUnits, parseUnits } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";

import {
  TOKEN_CONTRACT_ADDRESS,
  STAKING_CONTRACT_ADDRESS,
  STAKING_ABI,
  TOKEN_ABI,
} from "../contracts/contract";

function useContract() {
  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();
  const getProvider = () => {
    return new BrowserProvider(walletProvider);
  };
  const getSigner = async (provider) => {
    return provider.getSigner();
  };

  const getContract = async (address, abi, signer) => {
    const contract = new Contract(address, abi, signer);
    return contract;
  };

  const getStakingData = async () => {
    if (!address) {
      console.log(address);
      return;
    }
    const provider = getProvider();
    const signer = await getSigner(provider);
    const staking = await getContract(
      STAKING_CONTRACT_ADDRESS,
      STAKING_ABI,
      signer
    );
    let AllUSDTinvestments = await staking.getAllUSDTinvestments({
      from: address,
    });
    console.log(AllUSDTinvestments);
    // loop through all investments
    // make 4 arrays for each type of investment

    let type1 = [];
    let type2 = [];
    let type3 = [];
    let type4 = [];

    let reward = 0;

    let unstake = [];

    for (let i = 0; i < AllUSDTinvestments.length; i++) {
      let investment = AllUSDTinvestments[i];
      const _type = Number(investment[6]);

      const _id = Boolean(investment[4]);

      if (Boolean(investment[4]) === false) {
        unstake.push({
          id: Number(investment[8]),
          amount: formatUnits(investment[0].toString(), 18),
        });
      }

      if (Number(investment[6]) === 1) {
        type1.push(investment);
      } else if (Number(investment[6]) === 2) {
        type2.push(investment);
      } else if (Number(investment[6]) === 3) {
        type3.push(investment);
      } else if (Number(investment[6]) === 4) {
        type4.push(investment);
      }
    }
    console.log(unstake);

    // get total stake of each type
    let totalStake1 = 0;
    let totalStake2 = 0;
    let totalStake3 = 0;
    let totalStake4 = 0;

    for (let i = 0; i < type1.length; i++) {
      totalStake1 += Number(formatUnits(type1[i][0].toString(), 18));
    }
    for (let i = 0; i < type2.length; i++) {
      totalStake2 += Number(formatUnits(type2[i][0].toString(), 18));
    }
    for (let i = 0; i < type3.length; i++) {
      totalStake3 += Number(formatUnits(type3[i][0].toString(), 18));
    }
    for (let i = 0; i < type4.length; i++) {
      totalStake4 += Number(formatUnits(type4[i][0].toString(), 18));
    }
    console.log(totalStake1, totalStake2, totalStake3, totalStake4);

    const allStaked =
      Number(totalStake1) +
      Number(totalStake2) +
      Number(totalStake3) +
      Number(totalStake4);

    // convert to human readable
    const _reward = await staking.getTotalRewards({ from: address });
    reward = formatUnits(_reward.toString(), 18);

    return {
      type1,
      type2,
      type3,
      type4,
      totalStake1,
      totalStake2,
      totalStake3,
      totalStake4,
      reward,
      allStaked,
      unstake,
    };
  };

  const stake = async (amount, type) => {
    console.log(amount, type);
    const provider = getProvider();
    const signer = await getSigner(provider);
    const staking = await getContract(
      STAKING_CONTRACT_ADDRESS,
      STAKING_ABI,
      signer
    );
    const token = await getContract(TOKEN_CONTRACT_ADDRESS, TOKEN_ABI, signer);
    const transaction = await token.approve(
      STAKING_CONTRACT_ADDRESS,
      parseUnits(amount.toString(), 18)
    );
    await transaction.wait();
    const trx2 = await staking.Stake(parseUnits(amount.toString(), 18), type);
    await trx2.wait();
  };

  const unStake = async (_id) => {
    const provider = getProvider();
    const signer = await getSigner(provider);
    const staking = await getContract(
      STAKING_CONTRACT_ADDRESS,
      STAKING_ABI,
      signer
    );
    const trx2 = await staking.unStake(_id);
    await trx2.wait();
  };

  const withdrawReward = async () => {
    const provider = getProvider();
    const signer = await getSigner(provider);
    const staking = await getContract(
      STAKING_CONTRACT_ADDRESS,
      STAKING_ABI,
      signer
    );
    const trx2 = await staking.widrawAllReward();
    await trx2.wait();
  };

  return {
    getStakingData,
    stake,
    unStake,
    withdrawReward,
  };
}

export default useContract;
